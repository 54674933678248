
              @import "@/assets/css/variables.scss";
            







































































































































































/deep/ .el-tag.el-tag--info .el-tag__close {
  background-color: $text-placeholder;
  color: $white;
}

/deep/ .el-checkbox {
  display: flex;
}

/deep/ .el-checkbox__input {
  margin-top: 2px;
}

.agreement {
  @include rtl-sass-prop(margin-left, margin-right, 4px);

  p {
    font-size: 14px;
    color: $text-secondary;
    line-height: 21px;
  }
}
